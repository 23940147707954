<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
      v-loading="loading"
    >
      <div slot="title" style="color: #409eff">项目绩效调整变更记录</div>
      <div name="content" class="content">
        <el-descriptions
          style="margin-bottom: 10px"
          title="项目基础信息"
          direction="vertical"
          :column="7"
          border
        >
          <el-descriptions-item label="项目名称" :span="3">
            {{ projectInformation.projectName }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ projectInformation.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ projectInformation.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>
          <el-descriptions-item label="部门" :span="1">
            {{ projectInformation.theirDeptName }}
          </el-descriptions-item>
          <el-descriptions-item label="项目经理" :span="1">
            {{ projectInformation.managerName }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="content-maincon">
          <div class="content-left">
            <div class="content-text" style="color: blue">更改前</div>
            <el-descriptions title="项目费用结算" direction="vertical" :column="4" border>
              <template slot="extra">
                <span style="font-size: 17px; color: gray">单位：元</span>
              </template>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualAmount')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="项目实际金额"
                :span="1"
              >
                <span> {{ form.oldData.actualAmount | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item :span="1" label="税费">
                <span> {{ form.oldData.taxesFee | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="招标费" :span="1">
                <span> {{ form.oldData.tenderFee | thousands }}</span>
              </el-descriptions-item>

              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('disposableProjectExpense')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
              >
                <template slot="label">
                  <div>项目可支配费用</div>
                  <div style="font-size: 12px; color: red; margin-top: 10px">
                    项目可支配费用 = 项目实际金额 - 税费 - 招投标费用 - 外部协作费用
                  </div>
                </template>
                <span> {{ form.oldData.disposableProjectExpense | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productionCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="2"
              >
                <template slot="label">
                  <div>内部生产费用 =</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      内部生产费用 = 项目可支配费用 X 生产费用比例
                    </span>
                  </div>
                </template>
                <span> {{ form.oldData.productionCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('deptManagementPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
              >
                <template slot="label">
                  <div>（1） 部门管理绩效</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      部门管理绩效 = 内部生产费用 X 25%
                    </span>
                  </div>
                </template>
                <span>
                  {{ form.oldData.deptManagementPerformance | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('sumExpenseCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（2） 报销费用合计</div>
                </template>
                <span> {{ form.oldData.sumExpenseCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('sumStaffCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（3） 人员成本合计</div>
                </template>

                <span> {{ form.oldData.sumStaffCost | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('othersStaffCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（4） 其它人员成本</div>
                </template>

                <span> {{ form.oldData.othersStaffCost | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('outsourceSumCost')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（5） 外协生产费用合计</div>
                </template>
                <span> {{ form.oldData.outsourceSumCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('depreciationEquipmentFee')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                label="（6） 设备折旧"
              >
                <span>
                  {{ form.oldData.depreciationEquipmentFee | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('corporateProfit')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
              >
                <template slot="label"> 公司利润 </template>
                <span> {{ form.oldData.corporateProfit | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="2"
              >
                <template slot="label">
                  <div>
                    生产绩效
                    <span style="font-size: 12px; color: red; margin-left: 20px">
                      生产绩效 = 内部生产费用 - 部门管理绩效 - 报销费用合计 - 人员成本合计 -
                      其它人员成本 - 外协生产费用合计 - 设备折旧
                    </span>
                  </div>
                </template>
                <span>
                  {{ form.oldData.productPerformance | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualProductPerformance')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
              >
                <template slot="label"> 实际生产绩效 </template>
                <span>
                  {{ form.oldData.actualProductPerformance | thousands }}</span
                ></el-descriptions-item
              >
            </el-descriptions>

            <el-descriptions
              style="margin: 10px 0px"
              title="公司奖惩信息"
              direction="vertical"
              :column="1"
              border
            >
              <el-descriptions-item label="团队奖惩" :span="1">
                <div class="rp-inline">
                  <div class="rp-inline-money">
                    <span>奖惩金额：</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamSumAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      >{{ form.oldData.teamSumAmount }}</span
                    >
                  </div>
                  <div class="symbol">=</div>
                  <div class="rp-inline-money">
                    <span>奖励金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamBonusAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      placeholder="请输入金额"
                      >{{ form.oldData.teamBonusAmount }}</span
                    >
                  </div>
                  <div class="symbol">
                    <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                  </div>
                  <div class="rp-inline-money">
                    <span>惩罚金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamPenaltyAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      placeholder="请输入金额"
                      >{{ form.oldData.teamPenaltyAmount }}</span
                    >
                  </div>
                </div>
              </el-descriptions-item>

              <el-descriptions-item
                :span="1"
                :contentStyle="
                  form.changeFieldList.includes('teamAmountRemark')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
              >
                <template slot="label"> 金额备注 </template>
                {{ form.oldData.teamAmountRemark }}
              </el-descriptions-item>

              <el-descriptions-item label="个人奖惩" :span="1">
                <div class="rp-inline">
                  <div class="rp-inline-money">
                    <span>奖惩金额：</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffSumAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      >{{ form.oldData.staffSumAmount }}</span
                    >
                  </div>
                  <div class="symbol">=</div>
                  <div class="rp-inline-money">
                    <span>奖励金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffBonusAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      >{{ form.oldData.staffBonusAmount }}</span
                    >
                  </div>
                  <div class="symbol">
                    <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                  </div>
                  <div class="rp-inline-money">
                    <span>惩罚金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffPenaltyAmount')
                          ? 'rp-inline-money-input left-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      >{{ form.oldData.staffPenaltyAmount }}</span
                    >
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('staffAmountRemark')
                    ? { 'background-color': '#bff0de' }
                    : ''
                "
                :span="1"
              >
                <template slot="label"> 金额备注 </template>
                {{ form.oldData.staffAmountRemark }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
          <div>
            <div style="height: 60px"></div>
            <div class="vertical-dashed-line"></div>
          </div>
          <div class="content-right" style="color: red">
            <div class="content-right-view">
              <div class="content-text" style="font-wei">更改后</div>
            </div>
            <el-descriptions title="项目费用结算" direction="vertical" :column="4" border>
              <template slot="extra">
                <span style="font-size: 17px; color: gray">单位：元</span>
              </template>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualAmount')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="项目实际金额"
                :span="1"
              >
                <span> {{ form.newData.actualAmount | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item :span="1" label="税费">
                <span> {{ form.newData.taxesFee | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="招标费" :span="1">
                <span> {{ form.newData.tenderFee | thousands }}</span>
              </el-descriptions-item>

              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('disposableProjectExpense')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
              >
                <template slot="label">
                  <div>项目可支配费用</div>
                  <div style="font-size: 12px; color: red; margin-top: 10px">
                    项目可支配费用 = 项目实际金额 - 税费 - 招投标费用 - 外部协作费用
                  </div>
                </template>
                <span> {{ form.newData.disposableProjectExpense | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productionCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="2"
              >
                <template slot="label">
                  <div>内部生产费用 =</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      内部生产费用 = 项目可支配费用 X 生产费用比例
                    </span>
                  </div>
                </template>
                <span> {{ form.newData.productionCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('deptManagementPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
              >
                <template slot="label">
                  <div>（1） 部门管理绩效</div>
                  <div style="margin-top: 5px">
                    <span style="font-size: 12px; color: red">
                      部门管理绩效 = 内部生产费用 X 25%
                    </span>
                  </div>
                </template>
                <span>
                  {{ form.newData.deptManagementPerformance | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('sumExpenseCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（2） 报销费用合计</div>
                </template>
                <span> {{ form.newData.sumExpenseCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('sumStaffCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（3） 人员成本合计</div>
                </template>

                <span> {{ form.newData.sumStaffCost | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('othersStaffCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（4） 其它人员成本</div>
                </template>

                <span> {{ form.newData.othersStaffCost | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('outsourceSumCost')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label">
                  <div>（5） 外协生产费用合计</div>
                </template>
                <span> {{ form.newData.outsourceSumCost | thousands }}</span>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('depreciationEquipmentFee')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                label="（6） 设备折旧"
              >
                <span>
                  {{ form.newData.depreciationEquipmentFee | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :span="1"
                :contentStyle="
                  form.changeFieldList.includes('corporateProfit')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label"> 公司利润 </template>
                <span> {{ form.newData.corporateProfit | thousands }}</span></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('productPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="2"
              >
                <template slot="label">
                  <div>
                    生产绩效
                    <span style="font-size: 12px; color: red; margin-left: 20px">
                      生产绩效 = 内部生产费用 - 部门管理绩效 - 报销费用合计 - 人员成本合计 -
                      其它人员成本 - 外协生产费用合计 - 设备折旧
                    </span>
                  </div>
                </template>
                <span>
                  {{ form.newData.productPerformance | thousands }}</span
                ></el-descriptions-item
              >
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('actualProductPerformance')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
              >
                <template slot="label"> 实际生产绩效 </template>
                <span>
                  {{ form.newData.actualProductPerformance | thousands }}</span
                ></el-descriptions-item
              >
            </el-descriptions>

            <el-descriptions
              style="margin: 10px 0px"
              title="公司奖惩信息"
              direction="vertical"
              :column="1"
              border
            >
              <el-descriptions-item label="团队奖惩" :span="1">
                <div class="rp-inline">
                  <div class="rp-inline-money">
                    <span>奖惩金额：</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamSumAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      >{{ form.newData.teamSumAmount }}</span
                    >
                  </div>
                  <div class="symbol">=</div>
                  <div class="rp-inline-money">
                    <span>奖励金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamBonusAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      placeholder="请输入金额"
                      >{{ form.newData.teamBonusAmount }}</span
                    >
                  </div>
                  <div class="symbol">
                    <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                  </div>
                  <div class="rp-inline-money">
                    <span>惩罚金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('teamPenaltyAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      placeholder="请输入金额"
                      >{{ form.newData.teamPenaltyAmount }}</span
                    >
                  </div>
                </div>
              </el-descriptions-item>

              <el-descriptions-item
                :span="1"
                :contentStyle="
                  form.changeFieldList.includes('teamAmountRemark')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
              >
                <template slot="label"> 金额备注 </template>
                {{ form.newData.teamAmountRemark }}
              </el-descriptions-item>

              <el-descriptions-item label="个人奖惩" :span="1">
                <div class="rp-inline">
                  <div class="rp-inline-money">
                    <span>奖惩金额：</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffSumAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      >{{ form.newData.staffSumAmount }}</span
                    >
                  </div>
                  <div class="symbol">=</div>
                  <div class="rp-inline-money">
                    <span>奖励金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffBonusAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      >{{ form.newData.staffBonusAmount }}</span
                    >
                  </div>
                  <div class="symbol">
                    <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                  </div>
                  <div class="rp-inline-money">
                    <span>惩罚金额</span>
                    <span
                      :class="
                        form.changeFieldList.includes('staffPenaltyAmount')
                          ? 'rp-inline-money-input right-input-bgc'
                          : 'rp-inline-money-input'
                      "
                      style="margin-left: 8px"
                      >{{ form.newData.staffPenaltyAmount }}</span
                    >
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item
                :contentStyle="
                  form.changeFieldList.includes('staffAmountRemark')
                    ? { 'background-color': '#eef9b2' }
                    : ''
                "
                :span="1"
              >
                <template slot="label"> 金额备注 </template>
                {{ form.newData.staffAmountRemark }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 85,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      dictData: {
        projectSource: [], //项目来源 PROJECT_SOURCE
        businessLines: [], //业务种类 BUSINESS_LINES
      },
      form: {
        changeFieldList: [], //变更字段
        newData: {}, //	新数据
        oldData: {}, //	旧数据
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      CompanyMembers: state => state.project.CompanyMembers,
      projectInformation: state => state.project.projectInformation,
      projectDisposableExpenses: state => state.project.projectDisposableExpenses,
    }),
  },
  filters: {
    dateFormat(value) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd')
      } else {
        return ''
      }
    },
    thousands(a) {
      if (!a) {
        return a
      } else {
        a = String(a).replace(/,/g, '')
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  created() {
    this.getType()
  },
  mounted() {},
  methods: {
    getData() {
      this.loading = true
      this.$api.project
        .selectLogById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getType() {
      //业务种类 BUSINESS_LINES
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      //项目来源 PROJECT_SOURCE
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content-right-view {
  display: flex;
  justify-content: space-between;
}
.content-text {
  font-weight: 900;
  font-size: 20px;
  text-align: left;
}
.content-maincon {
  display: flex;
  justify-content: center;
  width: 100%;
  .content-left {
    width: 49%;
  }
  .content-right {
    width: 49%;
  }
  .vertical-dashed-line {
    margin: 0 5px;
    border-left: 3px dashed #ccc; /* 虚线的样式和颜色 */
    height: 93%; /* 虚线的高度 */
  }
}

.rp-inline {
  display: flex;
  justify-content: space-evenly;
  .symbol {
    line-height: 40px;
    font-size: 16px;
  }
  .rp-inline-money {
    display: flex;
    align-items: center;
  }
  .rp-inline-money-input {
    width: 130px;
    line-height: 40px;
    height: 40px;
    border: 1px solid #dee0e7;
    border-radius: 6px;
    padding-left: 8px;
  }
  .left-input-bgc {
    background-color: #bff0de;
  }
  .right-input-bgc {
    background-color: #eef9b2;
  }
}
.content {
  max-height: calc(90vh - 164px);
  overflow: auto;
}
</style>
